// components
import Img from 'gatsby-image';

// other
import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import PageTitle from '../components/atoms/PageTitle';
import NewsContent from '../components/molecules/NewsContent';
import BaseLayoutTemplate from '../components/environments/BaseLayoutTemplate';
import { newsCategoryStyle } from '../components/atoms/NewsCard';
import { breakpoint, mainColor } from '../components/theme';
import LinkButton from '../components/atoms/LinkButton';
import HeadBase from '../components/molecules/HeadBase';
import { CROCCHA_API } from '../utils';
import { ContentfulNews } from 'app/types/graphql-types';

type NewsDetailPageProps = {
  path: string;
  navigate: any;
  location: any;
  pageResources: any;
  uri: any;
  data: { contentfulNews: ContentfulNews };
  pageContext: any;
  pathContext: any;
};

const NewsDetailPage: React.FC<NewsDetailPageProps> = ({ data, ...props }) => {
  const {
    title,
    type,
    releaseDate,
    description,
    mainImage,
  } = data.contentfulNews;

  return (
    <BaseLayoutTemplate>
      <HeadBase
        metas={{
          title: `${title} | tryangle株式会社`,
          description:
            'ハンドメイド専用のSNSアプリを提供しているtryangle（トライアングル）株式会社の公式サイトです。',
          openGraph: {
            type: 'article',
            url:
              `${props.location?.href?.split('?')[0]}/` ??
              `${CROCCHA_API.SELF_DOMAIN}/news/`,
            title: `${title} | tryangle株式会社`,
            description:
              'ハンドメイド専用のSNSアプリを提供しているtryangle（トライアングル）株式会社の公式サイトです。',
            images: [
              {
                url:
                  'https://tryangle-croccha.s3-ap-northeast-1.amazonaws.com/company/ogp/ogp4.jpg',
                width: 1200,
                height: 630,
                alt: 'tryangle株式会社',
              },
            ],
            site_name: 'tryangle株式会社',
          },
        }}
      />

      <PageTitle title='News' span='ニュース' />
      {/* ニュース */}
      <Article>
        <NewsInfo>
          <Category>{type}</Category>
          <time>{releaseDate}</time>
        </NewsInfo>

        <h2>{title}</h2>

        <NewsContent description={description} />

        {!!mainImage && (
          <Temp>
            <Img alt={title} fluid={mainImage.fluid} />
          </Temp>
        )}
      </Article>

      <StyledLinkButton url='news'>ニュース一覧</StyledLinkButton>
    </BaseLayoutTemplate>
  );
};

export default NewsDetailPage;

export const query = graphql`
  query($newsId: String) {
    contentfulNews(newsId: { eq: $newsId }) {
      title
      type
      releaseDate(formatString: "YYYY/MM/DD")
      description {
        raw
      }
      mainImage {
        fluid(maxWidth: 1180, background: "rgb:000000") {
          ...GatsbyContentfulFluid_tracedSVG
        }
      }
    }
  }
`;

const Temp = styled.div`
  margin-top: 2rem;
  border-radius: 4px;
  width: 100%;
`;

const Article = styled.article`
  padding: 3rem 1rem;
  margin: 0 auto;
  font-size: 1rem;
  line-height: 1.6;
  @media ${breakpoint.sp} {
    max-width: 540px;
  }
  @media ${breakpoint.tab} {
    max-width: 800px;
  }
  > h2 {
    font-size: 22px;
    margin-bottom: 2rem;
  }
  > h3 {
    font-size: 1rem;
    margin: 1rem 0;
  }
  & a {
    color: ${mainColor};
    text-decoration: underline;
  }
`;

const NewsInfo = styled.div`
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const Category = styled.div`
  ${newsCategoryStyle};
  margin-right: 1rem;
  padding: 0.5rem;
`;

const StyledLinkButton = styled(LinkButton)`
  display: block !important;
  margin: 0 auto 100px !important;
`;
