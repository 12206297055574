// other
import React from 'react';
import { INLINES } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { ContentfulNewsDescription } from 'app/types/graphql-types';

const options = {
  renderNode: {
    [INLINES.HYPERLINK]: (node: any, next: string[]) => (
      <a href={node.data.uri} target='_blank'>
        {next[0]}
      </a>
    ),
  },
};

/**
 * scrapboxにまとめました
 * https://scrapbox.io/croccha-engineer/%E4%BC%9A%E7%A4%BEHP%E3%81%AE%E3%81%8A%E7%9F%A5%E3%82%89%E3%81%9B%E3%81%AE%E7%AE%A1%E7%90%86%E3%81%AB%E3%81%A4%E3%81%84%E3%81%A6#5e424cdba36dfb00003604a4
 */

type Props = {
  description: ContentfulNewsDescription;
};

const NewsContent: React.FC<Props> = ({ description }) => (
  <>{renderRichText({ raw: description.raw, references: [] }, options)}</>
);

export default NewsContent;
